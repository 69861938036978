<template>
  <a-form-model
    ref="registerPasswordForm"
    :model="form"
    :rules="rules"
    class="account-form"
  >
    <a-form-model-item prop="username" has-feedback>
      <a-input v-model="form.username" placeholder="请输入用户名">
        <img src="@/assets/images/login_username.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="password" has-feedback>
      <a-input
        v-model="form.password"
        placeholder="6-20位密码，区分大小写"
        type="password"
      >
        <img src="@/assets/images/login_password.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="confirmPassword" has-feedback>
      <a-input v-model="form.confirmPassword" placeholder="确认密码" type="password">
        <img src="@/assets/images/login_password.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="hasAgreed">
      <a-checkbox v-model="form.hasAgreed">
        同意<router-link to="/agreement">《服务协议》</router-link
        ><router-link to="/privacy">《隐私政策》</router-link>
      </a-checkbox>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="submit"> 注册 </a-button>
    </a-form-model-item>
    <a-form-model-item>
      <div class="d-f j-c">
        已有账号，
        <router-link to="/login"> 直接登录 </router-link>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { register } from "@/api/register.js";
export default {
  name: "RegisterPassword",
  data() {
    return {
      form: {},
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[A-Za-z][0-9A-Za-z]{2,12}$/,
            message: "用户名只能包括数字(不以数字开头)和字母且在3~12位之间",
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入密码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,
            message: "6-20位，字母和数字组合",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("两次密码不一样"));
              } else {
                callback();
              }
            },
            trigger: ["change", "blur"],
          },
        ],
        hasAgreed: [
          {
            type: "boolean",
            required: true,
            message: "请勾选同意《服务协议》《隐私政策》",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  methods: {
    //注册
    submit() {
      this.$refs.registerPasswordForm.validate((valid) => {
        if (valid) {
          this.form.mobile = this.$store.state.registerForm.mobile;
          this.form.schoolNum = this.$store.state.registerForm.schoolNum;
          let params = JSON.parse(JSON.stringify(this.form));
          delete params.hasAgreed;
          register(params).then((res) => {
            this.$message.success("注册成功，请联系管理员审核");
            this.$router.push("/login");
          });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.account-form {
  width: 100%;
  margin-top: 45px;
  ::v-deep .ant-input {
    border: 1px solid transparent;
    border-bottom: 1px solid #e8eaee;
  }
  ::v-deep .ant-input:hover {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input:focus {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input-affix-wrapper:hover {
    .ant-input:not(.ant-input-disabled) {
      border-color: transparent;
      border-bottom: 1px solid #e8eaee;
      box-shadow: none;
    }
  }
  ::v-deep .has-error {
    .ant-input {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:focus {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:hover {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
  }
  ::v-deep .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
  button {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
  .wx {
    margin-left: 18px;
  }
}
</style>
