<template>
  <div>
    <a-button @click="getCode" v-if="canSend"> 获取验证码</a-button>
    <a-button disabled v-else>{{ count }}s后可再次发送</a-button>
  </div>
</template>

<script>
export default {
  name: "SendCode",
  props: ["canSend", "totalCount"],
  data() {
    return {
      timer: null,
      count: null,
    };
  },
  watch: {
    canSend: {
      handler(n, o) {
        console.log(n);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    getCode() {
      clearInterval(this.timer);
      this.timer = null;
      let count = this.totalCount;
      this.count = count;
      this.timer = setInterval(() => {
        let canReduce = this.count < this.totalCount || this.count === this.totalCount;
        if (this.count > 0 && canReduce) {
          this.count--;
        } else {
          this.$emit("canSendAgain", true);
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 1000);

      // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
      this.$once("hook:beforeDestroy", () => {
        clearInterval(this.timer);
        this.timer = null;
      });
    },
  },
  created() {},

  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style scoped lang="less">
button {
  width: 100%;
  height: 36px;
  border-radius: 18px;
}
</style>
