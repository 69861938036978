import requestLogin from '@/utils/request-login'

//注册发送短信验证码
export function smsRegisterSend(mobile) {
    return requestLogin.get('/api-uaa/validata/sms/register-code/'+mobile)
}

//注册校验验证码
export function validateRegisterSend(params) {
    return requestLogin.get('/api-uaa/validata/sms/validate-code',params)
}

//注册
export function register(params) {
    return requestLogin.post('/api-user/users/register',params)
}