<template>
  <a-form-model
    ref="registerAccountForm"
    :model="form"
    :rules="rules"
    class="account-form"
  >
    <a-form-model-item prop="mobile" class="phone-item">
      <a-input v-model="form.mobile" placeholder="手机号">
        <span slot="prefix" class="phone-prefix">
          +86<img src="@/assets/images/register_arrow.png" width="12px" />
        </span>
      </a-input>
    </a-form-model-item>
    <a-row>
      <a-col :span="12">
        <a-form-model-item prop="code">
          <a-input v-model="form.code" placeholder="验证码">
            <img src="@/assets/images/login_code.png" width="16px" slot="prefix" />
          </a-input>
        </a-form-model-item>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :span="10">
        <send-code
          ref="codeRegister"
          :canSend="canSend"
          :totalCount="totalCount"
          @canSendAgain="getSendMessage"
          @click.native="getCode($event)"
        ></send-code>
      </a-col>
    </a-row>
    <a-form-model-item prop="schoolNum">
      <a-input v-model="form.schoolNum" placeholder="学校ID">
        <img src="@/assets/images/register_school_num.png" width="16px" slot="prefix" />
      </a-input>
    </a-form-model-item>
    <a-form-model-item prop="hasAgreed">
      <a-checkbox v-model="form.hasAgreed">
        同意<router-link to="/agreement">《服务协议》</router-link
        ><router-link to="/privacy">《隐私政策》</router-link>
      </a-checkbox>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" @click="next"> 下一步 </a-button>
    </a-form-model-item>
    <a-form-model-item>
      <div class="d-f j-c">
        已有账号，
        <router-link to="/login"> 直接登录 </router-link>
      </div>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import SendCode from "@/components/SendCode.vue";
import { smsRegisterSend, validateRegisterSend } from "@/api/register.js";
export default {
  name: "RegisterAccount",
  components: { SendCode },
  data() {
    return {
      form: {},
      rules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "手机号格式错误",
            trigger: ["change", "blur"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^\d{4}$/,
            message: "验证码格式错误",
            trigger: ["change", "blur"],
          },
        ],
        schoolNum: [
          {
            required: true,
            message: "请输入学校ID",
            whitespace: true,
            trigger: ["change", "blur"],
          },
          {
            pattern: /^\d{6}$/,
            message: "学校ID格式错误",
            trigger: ["change", "blur"],
          },
        ],
        hasAgreed: [
          {
            type: "boolean",
            required: true,
            message: "请勾选同意《服务协议》《隐私政策》",
            trigger: ["change", "blur"],
          },
        ],
      },
      canSend: true,
      totalCount: 60,
    };
  },
  methods: {
    //获取验证码
    getCode(event) {
      event.stopPropagation();
      this.$refs["registerAccountForm"].validateField(["mobile"], (Error) => {
        if (!Error) {
          if (this.canSend) {
            //这里可以插入$axios调用后台接口
            //验证手机号
            smsRegisterSend(this.form.mobile).then((res) => {
              this.$message.success("验证码发送成功");
              this.canSend = false;
              this.$refs.codeRegister.getCode();
            });
          }
        }
      });
    },
    //倒计时结束可以重新发送验证码（子组件传值）
    getSendMessage(data) {
      this.canSend = data;
    },
    //下一步
    next() {
      this.$refs.registerAccountForm.validate((valid) => {
        if (valid) {
          validateRegisterSend({
            validateType: "register",
            mobile: this.form.mobile,
            code: this.form.code,
          }).then((res) => {
            this.$store.state.registerForm.mobile = this.form.mobile;
            this.$store.state.registerForm.schoolNum = this.form.schoolNum;
            this.$emit("canNext", true);
          });
        }
      });
    },
  },
  created() {},
};
</script>

<style scoped lang="less">
.account-form {
  width: 100%;
  margin-top: 45px;
  ::v-deep .ant-input {
    border: 1px solid transparent;
    border-bottom: 1px solid #e8eaee;
  }
  ::v-deep .ant-input:hover {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input:focus {
    border-color: transparent;
    border-bottom: 1px solid #e8eaee;
    box-shadow: none;
  }
  ::v-deep .ant-input-affix-wrapper:hover {
    .ant-input:not(.ant-input-disabled) {
      border-color: transparent;
      border-bottom: 1px solid #e8eaee;
      box-shadow: none;
    }
  }
  ::v-deep .has-error {
    .ant-input {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:focus {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
    .ant-input:hover {
      border-color: transparent;
      border-bottom: 1px solid #f5222d;
      box-shadow: none;
    }
  }
  ::v-deep .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 40px;
    }
  }
  .phone-item {
    .phone-prefix {
      color: @primary-color;
      img {
        margin-left: 6px;
      }
    }
    ::v-deep .ant-input-affix-wrapper {
      .ant-input:not(:first-child) {
        padding-left: 65px;
      }
    }
  }
  button {
    width: 100%;
    height: 36px;
    border-radius: 18px;
  }
  .wx {
    margin-left: 18px;
  }
}
</style>
